.toast-error {
  background-color: #38a169 !important; /* Green background color */
  color: white !important; /* Text color */
}

.toast-container {
  background-color: #38a169;
  font-family: Arial, sans-serif; /* Font family */
}

.toast-error .Toastify__progress-bar {
  background: white !important;
}

.toast-error .Toastify__toast-icon > svg {
  fill: white !important; /* White exclamation icon */
}

.Toastify__close-button > svg {
  fill: var(--toastify-close-button-color) !important;
}

.custom-button {
  background-color: #38a169; /* Green background color */
  color: white; /* Text color */
  border: 2px solid transparent; /* Initial border */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}
.custom-button1 {
  opacity: 0.5; /* Reduced opacity for disabled state */
  cursor: not-allowed; /* Change cursor to not-allowed for disabled state */
  background-color: #d1d5db;
  border: 2px solid transparent; /* Lighter grey color for disabled state */
}

.back-button {
  background-color: transparent; /* Green background color */
  color: #38a169; /* Text color */
  /* border: 2px solid #38a169;
  transition: all 0.3s ease; */
}

.custom-button:hover {
  background-color: transparent;
  color: #38a169;
  border-color: #38a169;
}
.custom-border-radius {
  border-top-left-radius: 28px; /* Adjust the value as needed */
  border-bottom-left-radius: 28px; /* Adjust the value as needed */
  border-top-right-radius: 28px; /* Adjust the value as needed */
  border-bottom-right-radius: 28px;
}
.custom-bottom-border {
  background-color: #f7f7f7;
  border: none;
  padding: 0.6rem;
  /* border-bottom: 1px solid black; */
  border-radius: 0.2rem;
}
.custom-input-container {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  padding: 0.1rem;
  display: flex;
  align-items: center;
}

/* .custom-bottom-border:focus {
  border-bottom: 2px solid black;
} */

.custom-button:hover {
  background: transparent;
  color: #008000;
  font-weight: 600;
  border: 2px solid #38a169;
}

/* .main-div {
  background-image: url('3163534-uhd_3840_2160_30fps.gif');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100vw;
} */

.glassmorphism {
  background: rgba(255, 255, 255, 0.2); /* White with opacity */
  backdrop-filter: blur(14px); /* Blur the background */
  -webkit-backdrop-filter: blur(14px); /* For Safari support */
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
}
.triangle {
  position: absolute;
  bottom: -9px; /* Adjust this value as needed */
  left: 6px; /* Adjust this value as needed */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgb(241, 227, 227);
  z-index: 0;
}

.bullet-point {
  margin-right: 8px;
}
.tabbed {
  padding-left: 8px; /* Add padding to simulate a tab */
}

.met .text-sm {
  color: green; /* Color of the text when criteria is met */
  transition: color 0.3s ease-in-out;
}

.unmet .text-sm {
  color: red; /* Color of the text when criteria is not met */
  transition: color 0.3s ease-in-out;
}

/* .met .text-sm::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #888; 
  top: 50%;
  left: 0;
  animation: strikethrough 0.7s ease-in-out forwards;
}

@keyframes strikethrough {
  0% {
    width: 0;
    left: 0;
  }
  100% {
    width: 100%;
    left: 0;
  }
} */
/* @keyframes liveBackground {
  0% {
    background-color: greenyellow;
  }
  50% {
    background-color: yellow;
  }
  100% {
    background-color: greenyellow;
  }
} */
/* .glass-active1 {
  background-color: yellowgreen;
}
.glass-active {
  background-color: yellowgreen;
} */
.label.shrink {
  top: 0;
  transform: translateY(-150%);
  opacity: 1;
  font-size: 0.75rem;
}
.font-poppins {
  font-family: 'Poppins', sans-serif;
}
.custom-Phone-Input .react-tel-input input {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem; /* Adjust font size as needed */
  height: 3rem; /* Adjust height as needed */
  width: 100%;
  padding: 0.9rem;
  border: none;
  border-bottom: 2px solid #d1d5db; /* Example border color */
  background-color: transparent;
  color: black;
  line-height: 1.5;
}

:root {
  --toastify-color-dark: rgb(167, 244, 193);
  --toastify-text-color-dark: black;
  --circle-size: 400px; /* Default circle size */
  --logo-size: 50px; /* Default logo size */
  --toastify-close-button-color: black;
  --PhoneInputCountryFlag-aspectRatio: 0.333;
}
/* .PhoneInputCountryIconImg {
  display: none;
  width: 2rem !important;
} */

/* Adjust circle size for larger screens */
@media (min-width: 1200px) {
  :root {
    --circle-size: 600px; /* Larger circle size for bigger screens */
    --logo-size: 70px; /* Adjust logo size accordingly */
  }
}

@media (min-width: 1600px) {
  :root {
    --circle-size: 800px;
    --logo-size: 90px;
  }
  .otp-input {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
  }
  .custom-border-radius {
    width: 28vw !important;
  }
  .resend-otp-button {
    /* background-color: red !important; */
    align-items: flex-end !important;
  }
}

/* Hide logos and main image on smaller screens */
@media (max-width: 768px) {
  .circle-logo,
  .mainImage,
  .circle {
    display: none !important;
  }
  .AlendeiPlatforms {
    font-size: 1.5rem !important;
  }
  .formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    width: 100dvw;
  }
  .custom-border-radius {
    padding: 24px 16px;
    border-radius: 16px;
  }
  .otp-input {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    width: 10.6dvw !important;
    /* height: 4dvh !important; */
  }
  .form {
    width: 90vw !important;
    margin: 0vw !important;
  }
  .headertext {
    margin-top: -20px !important;
  }
  .welcometext {
    font-size: 1.2rem !important;
    margin-bottom: 0px !important;
  }
  .country-list {
    width: 50dvw !important;
  }
  .centered-container {
    background-color: white;
    padding: 16px 0px !important;
    width: 90dvw !important;
    height: auto;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow */
  }

  .thank-you-container {
    margin-bottom: 20px; /* Space between content and loading dots */
  }
}
.custom-Phone-Input .flag-dropdown {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.custom-Phone-Input .flag-dropdown .selected-flag {
  background: transparent !important;
}

.custom-Phone-Input .flag-dropdown .selected-flag:hover,
.custom-Phone-Input .flag-dropdown .selected-flag:focus {
  background: transparent !important;
}

.circle {
  /* background-color: red; */
  position: absolute;
  top: 45vh;
  left: 25vw;
  transform: translate(-50%, -50%);
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.circle-logo {
  position: absolute;
  width: var(--logo-size);
  height: var(--logo-size);
  animation: orbit 26s linear infinite, visibility 26s linear infinite;
}

.circle-logo:nth-child(1) {
  animation-delay: 0s, 0s;
}
.circle-logo:nth-child(2) {
  animation-delay: -2s, -2s;
}
.circle-logo:nth-child(3) {
  animation-delay: -4s, -4s;
}
.circle-logo:nth-child(4) {
  animation-delay: -6s, -6s;
}
.circle-logo:nth-child(5) {
  animation-delay: -8s, -8s;
}
.circle-logo:nth-child(6) {
  animation-delay: -10s, -10s;
}
.circle-logo:nth-child(7) {
  animation-delay: -12s, -12s;
}
.circle-logo:nth-child(8) {
  animation-delay: -14s, -14s;
}
.circle-logo:nth-child(9) {
  animation-delay: -16s, -16s;
}
.circle-logo:nth-child(10) {
  animation-delay: -18s, -18s;
}
.circle-logo:nth-child(11) {
  animation-delay: -20s, -20s;
}
.circle-logo:nth-child(12) {
  animation-delay: -22s, -22s;
}
.circle-logo:nth-child(13) {
  animation-delay: -24s, -24s;
}

@keyframes visibility {
  0%,
  10% {
    opacity: 1;
  }
  10%,
  10.5% {
    opacity: 0.8;
  }
  10.5%,
  11.5% {
    opacity: 0.7;
  }
  11.5%,
  11.67% {
    /* 0% to 150 degrees */
    /* visibility: visible; */
    opacity: 0.2;
  }
  11.68%,
  38.33% {
    /* 150 degrees to 210 degrees */
    /* visibility: hidden; */
    opacity: 0;
  }
  40.34%,
  42.34% {
    opacity: 0.2;
  }
  42.34%,
  43.32% {
    opacity: 0.7;
  }
  43.32%,
  44.34% {
    opacity: 0.8;
  }
  44.34%,
  100% {
    opacity: 1;
  }
}

@keyframes orbit {
  0% {
    transform: rotate(0deg) translateX(14vw) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(14vw) rotate(-360deg);
  }
}

@keyframes counter-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.main-image {
  z-index: 8;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: red;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px; /* This is for the horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.input-error {
  border: 1px solid red !important;
  border-radius: 4px !important;
}
.input-border-green {
  border: 1px solid gray !important;
  border-radius: 4px !important;
}
.input-border-green:focus {
  border: 1px solid green !important;
  border-radius: 4px !important;
}

/* .industry-dropdown select {
  size: 1rem;
  overflow-y: visible !important;
  height: auto !important;
} */

.industry-dropdown {
  position: relative;
}

.industry-dropdown .dropdown-menu {
  position: absolute;
  top: calc(100% + 10px); /* Adjust the spacing between input and dropdown */
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
}

.industry-dropdown.open .dropdown-menu {
  display: block;
}

.placeholder-text {
  color: #999;
}

.input-error {
  border-color: #dc3545;
}
.resend-otp-button {
  background: none;
  border: none;
  margin: 0;
  cursor: pointer;
  font-size: 0.78rem;
  color: #15803d !important;
  font-weight: 500;
  padding-right: 20px;
}
.resend-otp-button1:hover {
  text-decoration: none !important;
}
.resend-otp-button:hover {
  /* text-decoration: underline; */
}

.relative .text-black:hover + .absolute {
  display: block;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100; /* Ensure overlay is on top of everything */
}

.centered-container {
  background-color: white;
  padding: 20px;
  width: 50dvw;
  height: auto;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow */
}

.thank-you-container {
  margin-bottom: 20px; /* Space between content and loading dots */
}

.loading-dots {
  display: flex;
  justify-content: center;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: gray;
  border-radius: 50%;
  margin: 0 5px;
  animation: dot-animation 1s infinite linear;
}

@keyframes dot-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
